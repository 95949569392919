import React, { ReactElement } from 'react'
import ReactMarkdown from 'react-markdown'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { Layout } from './../components'

interface Data {
  heading: string
  subheading: string
}

const Index = (): ReactElement => {
  const { datoCmsLandingPage } = useStaticQuery(query)
  const { heading, subheading }: Data = datoCmsLandingPage
  return (
    <Layout>
      <>
        <header className='mb-8'>
          <h1 className='text-4xl font-semibold tracking-tight font-display'>
            {heading}
          </h1>
          <ReactMarkdown className='tracking-wide' source={subheading} />
        </header>
        <section className='tracking-wide'>
          I make random stuff like{' '}
          <Link
            className='underline hover:text-red-400 transition ease-out duration-200'
            to='/quarantine-name-generator'
          >
            this
          </Link>{' '}
          when I'm bored.
        </section>
      </>
    </Layout>
  )
}

const query = graphql`
  query LandingPageQuery {
    datoCmsLandingPage {
      subheading
      heading
    }
  }
`

export default Index
